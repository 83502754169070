import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddTransaction.css';

const AddTransaction = ({ tabs = [] }) => {
  const [selectedTabId, setSelectedTabId] = useState('');
  const [description, setDescription] = useState('');
  const [debit, setDebit] = useState('');
  const [credit, setCredit] = useState('');
  const navigate = useNavigate();

  const handleAddTransaction = () => {
    if (!selectedTabId) {
        alert('Please select a tab.');
        return;
    }
    if (!description || (!debit && !credit)) {
        alert('Please fill in all fields.');
        return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
        alert('Authentication error. Please log in.');
        return;
    }

    // Fetch the current tab and project balances
    fetch(`http://koryaal.com/api/tabs/${selectedTabId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error('Failed to fetch tab balance.');
            }
            return res.json();
        })
        .then((data) => {
            const currentTabBalance = data.tab_balance;
            const currentProjectBalance = data.project_balance;

            // Calculate the potential new balances
            const newTabBalance = currentTabBalance + (parseFloat(debit) || 0) - (parseFloat(credit) || 0);
            const newProjectBalance = currentProjectBalance + (parseFloat(debit) || 0) - (parseFloat(credit) || 0);

            // Validate balances
            if (newTabBalance < 0 || newProjectBalance < 0) {
                alert('Transaction would result in a negative balance. Denied.');
                return;
            }

            // Proceed to add the transaction if balances are valid
            return fetch('http://koryaal.com/api/transactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    tab_id: selectedTabId,
                    description,
                    debit: parseFloat(debit) || 0,
                    credit: parseFloat(credit) || 0,
                }),
            });
        })
        .then((res) => {
            if (res && !res.ok) {
                return res.json().then((error) => {
                    throw new Error(error.error || 'Failed to add transaction');
                });
            }
            if (res) return res.json();
        })
        .then(() => {
            alert('Transaction added successfully!');
            navigate('/'); // Navigate to the desired page after successful submission
        })
        .catch((error) => {
            console.error('Error adding transaction:', error.message);
            alert(error.message); // Show error message
        });
};



  const handleBack = () => {
    navigate('/'); 
  };

  return (
    <div className="transaction-form-container">
      <div className="transaction-form">
        <h4>Add Transaction</h4>
        <div className="form-group">
          <label>Select Item:</label>
          <select
            value={selectedTabId}
            onChange={(e) => setSelectedTabId(e.target.value)}
          >
            <option value="">Select Item</option>
            {tabs.map((tab) => (
              <option key={tab.id} value={tab.id}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Description:</label>
          <input
            type="text"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Debit:</label>
          <input
            type="number"
            placeholder="Debit"
            value={debit}
            onChange={(e) => setDebit(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Credit:</label>
          <input
            type="number"
            placeholder="Credit"
            value={credit}
            onChange={(e) => setCredit(e.target.value)}
          />
        </div>
        <div className="transaction-form-actions">
          <button onClick={handleAddTransaction} className="save-button">
            Add Transaction
          </button>
          <button onClick={handleBack} className="back-button">
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTransaction;
