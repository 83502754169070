import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CompleteProject.css";

const CompleteProject = ({ projects = [], onCompleteProject }) => {
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [completionDate, setCompletionDate] = useState("");
  const navigate = useNavigate();

  const handleCompleteProject = () => {
    if (!selectedProjectId || !completionDate) {
      alert("Please select a project and provide a completion date.");
      return;
    }

    onCompleteProject(selectedProjectId, completionDate);
  };

  return (
    <div className="complete-project-container">
      <h2>Complete Project</h2>
      <form>
        <div className="form-group">
          <label>Select Project</label>
          <select
            value={selectedProjectId}
            onChange={(e) => setSelectedProjectId(e.target.value)}
            className="form-input"
          >
            <option value="">-- Select Project --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Completion Date</label>
          <input
            type="date"
            value={completionDate}
            onChange={(e) => setCompletionDate(e.target.value)}
            className="form-input"
          />
        </div>
        <div className="form-actions">
          <button
            type="button"
            onClick={handleCompleteProject}
            className="complete-button"
          >
            Complete Project
          </button>
          <button
            type="button"
            onClick={() => navigate("/")}
            className="back-button"
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompleteProject;
