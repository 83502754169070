import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Tabs from "./components/Tabs";
import AddTransaction from "./components/AddTransaction";
import CompleteProject from "./components/CompleteProject";
import CashReportPage from "./components/CashReportPage";
import ProjectSummary from "./components/ProjectSummary";
import "./App.css";

const App = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [viewMode, setViewMode] = useState("tabs");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);

  const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) throw new Error("Failed to fetch");
    return response.json();
  };

  useEffect(() => {
    
    if (!sessionStorage.getItem("sessionStarted")) {
      sessionStorage.setItem("sessionStarted", "true");
      localStorage.removeItem("token");
      setIsAuthenticated(false);
    } else {
     
      const token = localStorage.getItem("token");
      setIsAuthenticated(!!token);
    }
  }, []);
  const handleLogin = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  const handleDepositCash = (projectId, updatedBalance) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId
          ? { ...project, balance: updatedBalance }
          : project
      )
    );

    if (selectedProject && selectedProject.id === projectId) {
      setSelectedProject((prevProject) => ({
        ...prevProject,
        balance: updatedBalance,
      }));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchWithAuth("http://koryaal.com/api/projects")
        .then((data) => {
          setProjects(data);
          if (data.length > 0) {
            setSelectedProject(data[0]);
            setTotalBalance(data[0].balance || 0);
          }
        })
        .catch((error) => console.error("Error fetching projects:", error));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (selectedProject) {
      fetchWithAuth(`http://koryaal.com/api/tabs/${selectedProject.id}`)
        .then((data) => {
          setTabs(data);
        })
        .catch((error) => console.error("Error fetching tabs:", error));
    }
  }, [selectedProject]);

  const handleCompleteProject = (projectId, completionDate) => {
    fetchWithAuth(`http://koryaal.com/api/projects/complete/${projectId}`, {
      method: "PUT",
      body: JSON.stringify({ completed_date: completionDate }),
    })
      .then(() => {
        alert("Project marked as completed!");
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.id === projectId
              ? {
                  ...project,
                  status: "completed",
                  completed_date: completionDate,
                }
              : project
          )
        );

        if (selectedProject && selectedProject.id === projectId) {
          setSelectedProject((prevSelected) => ({
            ...prevSelected,
            status: "completed",
            completed_date: completionDate,
          }));
        }
      })
      .catch((error) => console.error("Error completing project:", error));
  };

  const handleAddTab = (newTab) => {
    if (!selectedProject) {
      alert("No project selected. Please select a project to add a tab.");
      return;
    }

    fetchWithAuth("http://koryaal.com/api/tabs", {
      method: "POST",
      body: JSON.stringify({ ...newTab, project_id: selectedProject.id }),
    })
      .then((addedTab) => {
        setTabs((prevTabs) => [...prevTabs, addedTab]);
        alert("Tab added successfully!");
      })
      .catch((error) => console.error("Error adding tab:", error));
  };

  const handleAddProject = (newProject) => {
    const token = localStorage.getItem("token");
    fetch("http://koryaal.com/api/projects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newProject),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to add project");
        }
        return res.json();
      })
      .then((addedProject) => {
        setProjects((prevProjects) => [...prevProjects, addedProject]);
        alert("Project added successfully!");
      })
      .catch((error) => {
        console.error("Error adding project:", error);
        alert("Failed to add project. Please try again.");
      });
  };

  const handleUpdateTab = (tabId, newName) => {
    fetchWithAuth(`http://koryaal.com/api/tabs/${tabId}`, {
      method: "PUT",
      body: JSON.stringify({ name: newName }),
    })
      .then(() => {
        setTabs((prevTabs) =>
          prevTabs.map((tab) =>
            tab.id === tabId ? { ...tab, name: newName } : tab
          )
        );
        alert("Tab updated successfully!");
      })
      .catch((error) => console.error("Error updating tab:", error));
  };

  return (
    <Router>
      <div className="app">
        <Routes>
          {isAuthenticated ? (
            <>
              <Route
                path="/"
                element={
                  <>
                    <Sidebar
                      projects={projects}
                      tabs={tabs}
                      onProjectSelect={(project) => {
                        setSelectedProject(project);
                        setTotalBalance(project.balance || 0);
                        setViewMode("tabs");
                      }}
                      onLogout={handleLogout}
                      onUpdateTab={handleUpdateTab}
                      onAddProject={handleAddProject}
                      onCompleteProject={handleCompleteProject}
                      onDepositCash={handleDepositCash}
                      onViewCashReport={() => setViewMode("cashReport")}
                      onViewProjectSummary={() => setViewMode("projectSummary")}
                      // Added functionality to view cash report
                    />
                    <div className="main">
                      {selectedProject ? (
                        <>
                          <Header
                            project={selectedProject}
                            totalBalance={totalBalance}
                          />
                          <div className="content">
                            {viewMode === "tabs" && (
                              <Tabs
                                tabs={tabs}
                                setTabs={setTabs}
                                projectId={selectedProject.id}
                                onAddTab={handleAddTab}
                                onViewCashReport={() =>
                                  setViewMode("cashReport")
                                }
                                onViewProjectSummary={() =>
                                  setViewMode("projectSummary")
                                }
                              />
                            )}
                            {viewMode === "cashReport" && (
                              <CashReportPage
                                onBack={() => setViewMode("tabs")}
                              />
                            )}
                            {viewMode === "projectSummary" && (
                              <ProjectSummary
                                onBack={() => setViewMode("tabs")}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <p>
                          No projects available. Please add a project first.
                        </p>
                      )}
                    </div>
                  </>
                }
              />
              <Route path="/project-tab-summary" element={<ProjectSummary />} />

              <Route path="/cash-report" element={<CashReportPage />} />
              <Route
                path="/add-transaction/:tabId"
                element={<AddTransaction tabs={tabs} />}
              />
              <Route
                path="/complete-project"
                element={
                  <CompleteProject
                    projects={projects}
                    onCompleteProject={handleCompleteProject}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
