import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaProjectDiagram, FaCog, FaEdit, FaMoneyBillWave, FaUserCircle , FaChartBar, FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = ({
  projects,
  tabs,
  onProjectSelect,
  onLogout,
  onUpdateTab,
  onAddProject,
  onCompleteProject,
  onDepositCash,
  onWithdrawCash,
  onViewCashReport, 
  onViewProjectSummary// Added prop for viewing the cash report
}) => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('');
  const [userName, setUserName] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [showProjects, setShowProjects] = useState(true);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
  const [showCashReport, setShowCashReport] = useState(false);
  const [showCompleteProjectPopup, setShowCompleteProjectPopup] = useState(false);
  const [showDepositPopup, setShowDepositPopup] = useState(false);
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
  const [showProjectTabSummary, setShowProjectTabSummary] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [showReports, setShowReports] = useState(false); // Add this state



  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [amount, setAmount] = useState('');
  const [newTabName, setNewTabName] = useState('');
  const [selectedTabId, setSelectedTabId] = useState('');
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectStartDate, setNewProjectStartDate] = useState('');
  const [projectTabSummaryData, setProjectTabSummaryData] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user.company_name || 'Guest');
      setUserImage(user.imageURL || 'default-avatar.png'); // Fallback to a default image
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile if screen width <= 768px
      if (window.innerWidth > 768) setIsSidebarOpen(true); // Always open sidebar on desktop
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // Function to toggle the Reports section
  const toggleReports = () => {
    setShowReports((prev) => !prev);
  };
  const fetchProjectTabSummary = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('http://koryaal.com/api/project-tab-summary', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch project tab summary');
      }

      const data = await response.json();
      setProjectTabSummaryData(data);
      setShowProjectTabSummary(true);
    } catch (error) {
      console.error('Error fetching project tab summary:', error.message);
    }
  };

  const closeProjectTabSummary = () => {
    setShowProjectTabSummary(false);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    onLogout();
    navigate('/login');
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const toggleProjects = () => {
    setShowProjects(!showProjects);
  };

  const toggleCashReport = () => {
    setShowCashReport(!showCashReport);
    if (onViewCashReport) {
      onViewCashReport();
    }
  };

  // Deposit Cash
  const openDepositPopup = () => {
    setShowDepositPopup(true);
  };

  const closeDepositPopup = () => {
    setShowDepositPopup(false);
    setAmount('');
    setSelectedProjectId('');
  };

  const handleDepositCash = () => {
    if (!selectedProjectId || parseFloat(amount) <= 0) {
        alert('Please select a project and enter a valid deposit amount.');
        return;
    }

    fetch('http://koryaal.com/api/projects/deposit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            projectId: selectedProjectId,
            amount: parseFloat(amount),
        }),
    })
        .then(async (res) => {
            console.log('Response Status:', res.status); // Log status
            const data = await res.json();
            console.log('Response Data:', data); // Log response data
            if (data.success) {
                alert('Deposit successful');
                onDepositCash(selectedProjectId, data.totalBalance);
            } else {
                alert(`Deposit failed: ${data.message}`);
            }
        })
        .catch((err) => console.error('Fetch Error:', err));
};



  // Withdraw Cash
  const openWithdrawPopup = () => {
    setShowWithdrawPopup(true);
  };

  const closeWithdrawPopup = () => {
    setShowWithdrawPopup(false);
    setAmount('');
    setSelectedProjectId('');
  };

  const handleWithdrawCash = () => {
    if (!selectedProjectId || parseFloat(amount) <= 0) {
        alert('Please select a project and enter a valid withdrawal amount.');
        return;
    }

    fetch('http://koryaal.com/api/projects/withdraw', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            projectId: selectedProjectId,
            amount: parseFloat(amount),
        }),
    })
        .then(async (res) => {
            const data = await res.json();
            console.log('Withdraw Response:', data); // Debugging

            if (data.success) {
                alert('Withdrawal successful');
                onWithdrawCash(selectedProjectId, data.totalBalance); // Update the balance in the parent component
            } else {
                alert(`Withdrawal failed: ${data.message}`); // Display the error message from the backend
            }
        })
        .catch((err) => console.error('Error making withdrawal:', err));

    closeWithdrawPopup();
};

  
  // Add Project
  const openAddProjectPopup = () => {
    setShowAddProjectPopup(true);
  };

  const closeAddProjectPopup = () => {
    setShowAddProjectPopup(false);
    setNewProjectName('');
    setNewProjectStartDate('');
  };

  const handleAddProject = () => {
    if (!newProjectName || !newProjectStartDate) {
      alert('Please fill in all fields.');
      return;
    }
    onAddProject({ name: newProjectName, start_date: newProjectStartDate });
    closeAddProjectPopup();
  };

  // Complete Project
  const openCompleteProjectPopup = () => {
    setShowCompleteProjectPopup(true);
  };

  const closeCompleteProjectPopup = () => {
    setShowCompleteProjectPopup(false);
    setSelectedProjectId('');
  };
  const handleCompleteProject = () => {
    if (!selectedProjectId) {
      alert('Please select a project to mark as complete.');
      return;
    }
  
    const completionDate = new Date().toISOString();
  
    fetch(`http://koryaal.com/api/projects/complete/${selectedProjectId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ completed_date: completionDate }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('Project marked as completed!');
          onCompleteProject(selectedProjectId, completionDate); // Update parent component
          closeCompleteProjectPopup();
        } else {
          alert(data.message || 'Failed to complete the project.');
        }
      })
      .catch((err) => {
        console.error('Error completing project:', err);
        alert('An error occurred while completing the project.');
      });
  };
  

  // Edit Tab Name
  const openEditPopup = () => {
    setShowEditPopup(true);
  };

  const closeEditPopup = () => {
    setShowEditPopup(false);
    setNewTabName('');
    setSelectedTabId('');
  };

  const handleEditTab = () => {
    if (!selectedTabId || !newTabName) {
      alert('Please select a tab and provide a new name.');
      return;
    }
    onUpdateTab(selectedTabId, newTabName);
    closeEditPopup();
  };

  // Open Cash Report Popup
  
  return (
    <>
     {/* Hamburger Menu for Mobile */}
      {/* Hamburger Menu */}
      <button className="hamburger-menu" onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      {/* User Info Section */}
      <div className="user-info">
        <FaUserCircle className="user-avatar" />
        <h3 className="user-name">{userName || 'Guest'}</h3>
      </div>
  
      {/* Projects Section */}
      <button className="menu-button" onClick={toggleProjects}>
        <FaProjectDiagram className="icon" /> Projects
      </button>
      {showProjects && (
        <ul className="project-list">
          {projects
            .slice() // Avoid mutating the original array
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort  alphabetically
            .map((project) => (
              <li
                key={project.id}
                className="project-item"
                onClick={() => onProjectSelect(project)}
              >
                {project.name}
              </li>
            ))}
        </ul>
      )}
  

  
      {/* Settings Section */}
      <button className="menu-button" onClick={toggleSettings}>
        <FaCog className="icon" /> Settings  
      </button>
      {showSettings && (
        <div className="settings-menu">
          <button className="menu-button" onClick={openAddProjectPopup}>
            + Add New Project
          </button>
          <button className="menu-button" onClick={openCompleteProjectPopup}>
            Complete Project
          </button>
          <button className="menu-button" onClick={openEditPopup}>
            <FaEdit className="icon" /> Edit Tab Name
          </button>
          <button className="menu-button" onClick={openDepositPopup}>
            <FaMoneyBillWave className="icon" /> Deposit Cash
          </button>
          <button className="menu-button" onClick={openWithdrawPopup}>
            <FaMoneyBillWave className="icon" /> Withdraw Cash
          </button>
        </div>
      )}
            {/* Reports Section */}
            <button className="menu-button" onClick={toggleReports}>
        <FaChartBar className="icon" /> Reports
      </button>
      {showReports && (
        <div className="reports-menu">
          <button className="menu-button" onClick={() => onViewCashReport()}>
   Cash Report
</button>
          <button
        className="menu-button"
        onClick={onViewProjectSummary} // Switch to Project Summary view
      >
        Projects & Tabs Report
      </button>
        </div>
      )}
  
      {/* Logout Button */}
      <button className="menu-button logout-button" onClick={handleLogout}>
        <FaSignOutAlt className="icon" /> Logout
      </button>
      {/* Popups */}
      {/* Add Project Popup */}
      {showProjectTabSummary && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h4>Project Tab Summary</h4>
            <table className="summary-table">
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Tab Name</th>
                  <th>Total Debit</th>
                  <th>Total Credit</th>
                  <th>Tab Balance</th>
                </tr>
              </thead>
              <tbody>
                {projectTabSummaryData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.project_name}</td>
                    <td>{item.tab_name}</td>
                    <td>{item.total_debit}</td>
                    <td>{item.total_credit}</td>
                    <td>{item.tab_balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="close-button" onClick={closeProjectTabSummary}>
              Close
            </button>
          </div>
        </div>
      )}
      {showAddProjectPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h4>Add New Project</h4>
            <input
              type="text"
              placeholder="Project Name"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
            />
            <input
              type="date"
              value={newProjectStartDate}
              onChange={(e) => setNewProjectStartDate(e.target.value)}
            />
            <button onClick={handleAddProject}>Add</button>
            <button onClick={closeAddProjectPopup}>Cancel</button>
          </div>
        </div>
      )}

      {/* Complete Project Popup */}
      {showCompleteProjectPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h4>Complete Project</h4>
            <label>Select Project:</label>
            <select
              value={selectedProjectId}
              onChange={(e) => setSelectedProjectId(e.target.value)}
            >
              <option value="">-- Select Project --</option>
              {projects
                .filter((project) => project.status !== 'completed')
                .map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
            </select>
            <div className="popup-buttons">
              <button onClick={handleCompleteProject}>Mark as Complete</button>
              <button onClick={closeCompleteProjectPopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Tab Popup */}
      {showEditPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h4>Edit Tab Name</h4>
            <select
              value={selectedTabId}
              onChange={(e) => setSelectedTabId(e.target.value)}
            >
              <option value="">-- Select Tab --</option>
              {tabs.map((tab) => (
                <option key={tab.id} value={tab.id}>
                  {tab.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="New Tab Name"
              value={newTabName}
              onChange={(e) => setNewTabName(e.target.value)}
            />
            <button onClick={handleEditTab}>Save</button>
            <button onClick={closeEditPopup}>Cancel</button>
          </div>
        </div>
      )}

      {/* Deposit Cash Popup */}
      {showDepositPopup && (
  <div className="popup-overlay">
    <div className="popup-container">
      <h4>Deposit Cash</h4>
      <select
        value={selectedProjectId}
        onChange={(e) => setSelectedProjectId(e.target.value)}
      >
        <option value="">-- Select Project --</option>
        {projects.map((project) => (
          <option key={project.id} value={project.id}>
            {project.name}
          </option>
        ))}
      </select>
      <input
        type="number"
        placeholder="Enter Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <button onClick={handleDepositCash}>Deposit</button>
      <button onClick={closeDepositPopup}>Cancel</button>
    </div>
  </div>
)}


      {/* Withdraw Cash Popup */}
      {showWithdrawPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h4>Withdraw Cash</h4>
            <select
              value={selectedProjectId}
              onChange={(e) => setSelectedProjectId(e.target.value)}
            >
              <option value="">-- Select Project --</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              placeholder="Enter Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button onClick={handleWithdrawCash}>Withdraw</button>
            <button onClick={closeWithdrawPopup}>Cancel</button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default Sidebar;
