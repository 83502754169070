import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faTrash,
  faFileExcel,
  faPrint,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Tabs.css";

const Tabs = ({ tabs, projectId, onAddTab, updateProjectBalance }) => {
  const [newTab, setNewTab] = useState({ name: "", balance: "" });
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedTabName, setSelectedTabName] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isReportTab, setIsReportTab] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [filteredReportData, setFilteredReportData] = useState([]);
  const [filterItemName, setFilterItemName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Reset all state when the project changes
    setSelectedTab(null);
    setSelectedTabName("");
    setTransactions([]);
    setFilteredTransactions([]);
    setIsReportTab(false);
    setReportData([]);
    setFilteredReportData([]);
    setFilterItemName("");
  }, [projectId]);

  const fetchTransactions = (tabId, tabName) => {
    setIsReportTab(false);
    setTransactions([]);
    setFilteredTransactions([]);
    const token = localStorage.getItem("token");

    fetch(`http://koryaal.com/api/transactions/${tabId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch transactions");
        }
        return res.json();
      })
      .then((data) => {
        setSelectedTab(tabId);
        setSelectedTabName(tabName);
        setTransactions(data);
        setFilteredTransactions(data);
      })
      .catch((error) => console.error("Error fetching transactions:", error));
  };

  const fetchReportData = () => {
    setIsReportTab(true);
    setTransactions([]);
    setFilteredTransactions([]);
    const token = localStorage.getItem("token");

    fetch(`http://koryaal.com/api/tabs-report?projectId=${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setReportData(data);
          setFilteredReportData(data);
        } else {
          setReportData([]);
          setFilteredReportData([]);
        }
        setSelectedTab("report");
        setSelectedTabName("Report");
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
        setReportData([]);
        setFilteredReportData([]);
      });
  };

  const handleAddTab = () => {
    if (!newTab.name) {
      alert("Please provide a tab name.");
      return;
    }
    onAddTab(newTab);
    setNewTab({ name: "", balance: "" });
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = transactions.filter((transaction) =>
      transaction.description.toLowerCase().includes(term)
    );
    setFilteredTransactions(filtered);
  };

  const handleFilterItemName = (e) => {
    const itemName = e.target.value;
    setFilterItemName(itemName);
    const filtered = reportData.filter(
      (item) => itemName === "" || item.tab_name === itemName
    );
    setFilteredReportData(filtered);
  };
  const handleExportPDF = () => {
    const doc = new jsPDF();
    const dataToExport = isReportTab
      ? filteredReportData
      : filteredTransactions;

    if (dataToExport.length === 0) {
      alert("No data to export.");
      return;
    }

    const tableColumns = isReportTab
      ? ["No", "Item Name", "Date", "Description", "Debit", "Credit"]
      : ["Date", "Description", "Debit", "Credit", "Balance"];

    const tableRows = dataToExport.map((item, index) => {
      if (isReportTab) {
        return [
          index + 1,
          item.tab_name,
          new Date(item.date).toLocaleDateString(),
          item.description,
          `$${Number(item.debit || 0).toFixed(2)}`,
          `$${Number(item.credit || 0).toFixed(2)}`,
        ];
      } else {
        return [
          new Date(item.date).toLocaleDateString(),
          item.description,
          `$${Number(item.debit || 0).toFixed(2)}`,
          `$${Number(item.credit || 0).toFixed(2)}`,
          `$${Number(item.balance || 0).toFixed(2)}`,
        ];
      }
    });

    doc.text(
      isReportTab ? "Report Data" : `Transactions for Item: ${selectedTabName}`,
      14,
      10
    );
    doc.autoTable({
      startY: 20,
      head: [tableColumns],
      body: tableRows,
    });

    doc.save(`${isReportTab ? "Report" : selectedTabName}_Transactions.pdf`);
  };

  const handleExportExcel = () => {
    const dataToExport = isReportTab
      ? filteredReportData
      : filteredTransactions;

    if (dataToExport.length === 0) {
      alert("No data to export.");
      return;
    }

    const formattedData = dataToExport.map((transaction) => ({
      "Item Name": isReportTab ? transaction.tab_name : selectedTabName,
      Date: new Date(transaction.date).toLocaleDateString(),
      Description: transaction.description,
      Debit: Number(transaction.debit || 0).toFixed(2),
      Credit: Number(transaction.credit || 0).toFixed(2),
      // Balance: isReportTab ? undefined : Number(transaction.balance || 0).toFixed(2), // Removed
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      isReportTab ? "Report" : selectedTabName
    );
    XLSX.writeFile(
      workbook,
      `${isReportTab ? "Report" : selectedTabName}_Transactions.xlsx`
    );
  };

  const handlePrintTable = () => {
    const printContent = document.getElementById("print-table").outerHTML;
    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.write(
      "<html><head><title>Print Table</title></head><body>"
    );
    newWindow.document.write(printContent);
    newWindow.document.write("</body></html>");
    newWindow.document.close();
    newWindow.print();
    newWindow.close();
  };

  // const handleEditTransaction = (transactionId) => {
  //   navigate(`/edit-transaction/${transactionId}`);
  // };

  // const handleDeleteTransaction = (transactionId) => {
  //   if (window.confirm('Are you sure you want to delete this transaction?')) {
  //     const token = localStorage.getItem('token');
  //     fetch(`http://koryaal.com/api/transactions/${transactionId}`, {
  //       method: 'DELETE',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (!res.ok) {
  //           throw new Error('Failed to delete transaction');
  //         }
  //         setTransactions(transactions.filter((trans) => trans.id !== transactionId));
  //         setFilteredTransactions(filteredTransactions.filter((trans) => trans.id !== transactionId));
  //       })
  //       .catch((error) => console.error('Error deleting transaction:', error));
  //   }
  // };


  return (
    <div className="tabs-container">
      <h3>Items for Project</h3>
      {tabs.length > 0 ? (
        <div className="tabs">
          {tabs.map((tab) => (
            <div key={tab.id} className="tab-item">
              <button
                className={`tab-button ${
                  selectedTab === tab.id ? "active-tab" : ""
                }`}
                onClick={() => fetchTransactions(tab.id, tab.name)}
              >
                {tab.name}
              </button>
            </div>
          ))}
          <div className="tab-item">
            <button
              className={`tab-button report-button ${
                isReportTab ? "active-report-tab" : ""
              }`}
              onClick={fetchReportData}
            >
              All Items Report
            </button>
          </div>
        </div>
      ) : (
        <p>No items found for this project.</p>
      )}

      <div className="add-tab-form">
        <input
          type="text"
          placeholder="Add new Item"
          value={newTab.name}
          onChange={(e) => setNewTab({ name: e.target.value })}
        />
        <button onClick={handleAddTab}>Add Item</button>
      </div>

      {isReportTab && (
        <div className="transactions-section">
          <div className="filters">
            <label htmlFor="filter-item-name">Filter by Item Name:</label>
            <select
              id="filter-item-name"
              value={filterItemName}
              onChange={handleFilterItemName}
              className="filter-dropdown"
            >
              <option value="">All Items</option>
              {[...new Set(reportData.map((item) => item.tab_name))].map(
                (itemName, index) => (
                  <option key={index} value={itemName}>
                    {itemName}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="action-icons">
            <button
              className="icon-button export-button"
              onClick={handleExportExcel}
              title="Export Report to Excel"
            >
              <FontAwesomeIcon icon={faFileExcel} />
            </button>
            <button
              className="icon-button print-button"
              onClick={handlePrintTable}
              title="Print Table"
            >
              <FontAwesomeIcon icon={faPrint} />
            </button>
            <button
              className="icon-button export-pdf-button"
              onClick={handleExportPDF}
              title="Export Report to PDF"
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </button>
          </div>
          <div id="print-table">
            <table className="transactions-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item Name</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {filteredReportData.map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{report.tab_name}</td>
                    <td>{new Date(report.date).toLocaleDateString()}</td>
                    <td>{report.description}</td>
                    <td>${Number(report.debit || 0).toFixed(2)}</td>
                    <td>${Number(report.credit || 0).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {!isReportTab && selectedTab && (
        <div className="transactions-section">
          <div className="add-transaction-container">
            <input
              type="text"
              placeholder="Search transactions..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
            <button
              className="icon-button add-transaction-button"
              onClick={() => navigate(`/add-transaction/${selectedTab}`)}
              title="Add Transaction"
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button
              className="icon-button export-button"
              onClick={handleExportExcel}
              title="Export to Excel"
            >
              <FontAwesomeIcon icon={faFileExcel} />
            </button>
            <button
              className="icon-button print-button"
              onClick={handlePrintTable}
              title="Print Table"
            >
              <FontAwesomeIcon icon={faPrint} />
            </button>
            <button
              className="icon-button export-pdf-button"
              onClick={handleExportPDF}
              title="Export to PDF"
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </button>
          </div>

          <div id="print-table">
            <h4>Transactions for Item: {selectedTabName}</h4>
            <table className="transactions-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                  {/* <th>Actions</th> New column for actions */}
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((trans, index) => (
                  <tr key={index}>
                    <td>{new Date(trans.date).toLocaleDateString()}</td>
                    <td>{trans.description}</td>
                    <td>${Number(trans.debit || 0).toFixed(2)}</td>
                    <td>${Number(trans.credit || 0).toFixed(2)}</td>
                    <td>${Number(trans.balance || 0).toFixed(2)}</td>
                    {/* <td> */}
                    {/* <button
          className="icon-button"
          onClick={() => handleEditTransaction(trans.id)}
          title="Edit Transaction"
        > */}
                    {/* <FontAwesomeIcon icon={faEdit} /> */}
                    {/* </button> */}
                    {/* <button
          className="icon-button"
          onClick={() => handleDeleteTransaction(trans.id)}
          title="Delete Transaction"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button> */}
                    {/* </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tabs;




