import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { FaFileExcel, FaPrint } from "react-icons/fa";
import "./CashReportPage.css";

const CashReportPage = ({ onBack }) => {
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectFilter, setProjectFilter] = useState("");
  const [uniqueProjects, setUniqueProjects] = useState([]);

  useEffect(() => {
    const fetchFinancialData = async () => {
      setLoading(true);

      try {
        const token = localStorage.getItem("token");
        const response = await fetch("http://koryaal.com/api/finances", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data.");
        }

        const financesData = await response.json();
        const sortedFinances = (financesData.finances || []).sort(
          (a, b) => new Date(a.transaction_date) - new Date(b.transaction_date)
        );

        setBalanceHistory(sortedFinances);

        // Extract unique project names
        const projects = [
          ...new Set(
            sortedFinances.map(
              (record) => record.project_name || "Unknown Project"
            )
          ),
        ];
        setUniqueProjects(projects);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchFinancialData();
  }, []);

  // Apply dropdown-based filtering
  const filteredHistory = balanceHistory.filter(
    (record) => projectFilter === "" || record.project_name === projectFilter
  );

  const handleExportToExcel = () => {
    if (filteredHistory.length === 0) {
      alert("No data available to export.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(
      filteredHistory.map((record) => ({
        "Project Name": record.project_name || "Unknown Project",
        Type: record.finance_type,
        Amount: record.amount,
        Date: new Date(record.transaction_date).toLocaleString(),
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Cash Report");
    XLSX.writeFile(
      workbook,
      `${projectFilter || "All_Projects"}_Cash_Report.xlsx`
    );
  };

  const handlePrintTable = () => {
    const printContent = `
      <h2 style="text-align: center; font-size: 28px; color: #0056b3; font-weight: bold; margin-bottom: 20px;">
        Cash Report
      </h2>
      ${document.getElementById("print-section").outerHTML}
    `;
    const style = `
      <style>
        body {
          font-family: Arial, sans-serif;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 18px;
          border: 1px solid #ccc;
          margin: auto;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 15px;
          text-align: center;
        }
        th {
          background-color: #007bff;
          color: white;
          font-size: 20px;
          font-weight: bold;
        }
        tr:nth-child(even) {
          background-color: #f9f9f9;
        }
        tr:hover {
          background-color: #f1f1f1;
        }
      </style>
    `;
    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.write("<html><head><title>Print Table</title>");
    newWindow.document.write(style);
    newWindow.document.write("</head><body>");
    newWindow.document.write(printContent);
    newWindow.document.write("</body></html>");
    newWindow.document.close();
    newWindow.print();
    newWindow.close();
  };

  return (
    <div className="cash-report-page">
      <div className="non-printable">
        <button className="back-to-tabs-button" onClick={onBack}>
          Back to Tabs
        </button>
        <h2>Cash Report</h2>

        <div className="filter-container">
          <label htmlFor="project-filter" className="filter-label">
            Filter by Project Name:
          </label>
          <select
            id="project-filter"
            value={projectFilter}
            onChange={(e) => setProjectFilter(e.target.value)}
            className="filter-dropdown"
          >
            <option value="">All Projects</option>
            {uniqueProjects.map((project, index) => (
              <option key={index} value={project}>
                {project}
              </option>
            ))}
          </select>
        </div>

        <div className="action-icons">
          <FaFileExcel
            className="excel-icon"
            onClick={handleExportToExcel}
            title="Export to Excel"
          />
          <FaPrint
            className="print-icon"
            onClick={handlePrintTable}
            title="Print Table"
          />
        </div>
      </div>

      {error && <p className="error-message">{error}</p>}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="cash-report-table-container" id="print-section">
          <table className="cash-report-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Project Name</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredHistory.length > 0 ? (
                filteredHistory.map((record, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{record.project_name || "Unknown Project"}</td>
                    <td>{record.finance_type}</td>
                    <td>${Number(record.amount || 0).toFixed(2)}</td>
                    <td>
                      {new Date(record.transaction_date).toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No financial records available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CashReportPage;
