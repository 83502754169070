import React, { useState, useEffect } from "react";
import { FaPrint, FaFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";
import "./ProjectSummary.css";

const ProjectSummary = ({ onBack }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ itemName: "", projectName: "" });
  const [uniqueProjects, setUniqueProjects] = useState([]);
  const [uniqueItems, setUniqueItems] = useState([]);

  // Fetch project tab summary data
  const fetchProjectTabSummary = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "http://koryaal.com/api/project-tab-summary",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch project tab summary");
      }

      const fetchedData = await response.json();
      setData(fetchedData);

      // Extract unique project names and item names
      const projects = [
        ...new Set(fetchedData.map((item) => item.project_name || "N/A")),
      ];
      const items = [
        ...new Set(fetchedData.map((item) => item.tab_name || "N/A")),
      ];
      setUniqueProjects(projects);
      setUniqueItems(items);
    } catch (error) {
      console.error("Error fetching project tab summary:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectTabSummary();
  }, []);

  const getProjectNumbers = (data) => {
    const projectMap = new Map();
    let counter = 1;

    return data.map((item) => {
      if (!projectMap.has(item.project_name)) {
        projectMap.set(item.project_name, counter++);
      }
      return {
        ...item,
        project_number: projectMap.get(item.project_name),
      };
    });
  };

  const processedData = getProjectNumbers(data);

  const filteredData = processedData.filter((item) => {
    const itemNameMatch =
      filters.itemName === "" || item.tab_name === filters.itemName;
    const projectNameMatch =
      filters.projectName === "" || item.project_name === filters.projectName;
    return itemNameMatch && projectNameMatch;
  });

  const handleExportToExcel = () => {
    if (filteredData.length === 0) {
      alert("No data available to export.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((item) => ({
        "Project Number": item.project_number,
        "Project Name": item.project_name || "N/A",
        "Total Deposit": `$${Number(item.total_deposit || 0).toFixed(2)}`,
        "Total Withdrawals": `$${Number(item.total_withdrawals || 0).toFixed(
          2
        )}`,
        "Current Balance": `$${Number(item.current_balance || 0).toFixed(2)}`,
        "Tab Name": item.tab_name || "N/A",
        "Total Debit": `$${Number(item.total_debit || 0).toFixed(2)}`,
        "Total Credit": `$${Number(item.total_credit || 0).toFixed(2)}`,
        "Tab Balance": `$${Number(item.tab_balance || 0).toFixed(2)}`,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Project Tab Summary");
    XLSX.writeFile(workbook, "Project_Tab_Summary.xlsx");
  };

  const handlePrint = () => {
    const printContent = `
      <h2 style="text-align: center; font-size: 28px; color: #0056b3; font-weight: bold; margin-bottom: 20px;">
        Projects & Items Report Summary
      </h2>
      ${document.getElementById("print-section").outerHTML}
    `;
    const style = `
      <style>
        body {
          font-family: Arial, sans-serif;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 18px;
          border: 1px solid #ccc;
          margin: auto;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        th, td {
          border: 1px solid #ddd;
          padding: 15px;
          text-align: center;
        }
        th {
          background-color: #007bff;
          color: white;
          font-size: 20px;
          font-weight: bold;
        }
        tr:nth-child(even) {
          background-color: #f9f9f9;
        }
        tr:hover {
          background-color: #f1f1f1;
        }
      </style>
    `;
    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.write("<html><head><title>Print Table</title>");
    newWindow.document.write(style);
    newWindow.document.write("</head><body>");
    newWindow.document.write(printContent);
    newWindow.document.write("</body></html>");
    newWindow.document.close();
    newWindow.print();
    newWindow.close();
  };

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
        <button onClick={fetchProjectTabSummary} className="retry-button">
          Retry
        </button>
      </div>
    );

  return (
    <div className="project-summary">
      <div className="non-printable">
        <button onClick={onBack} className="back-button">
          Back to Tabs
        </button>
        <h2>Projects & Items Report Summary</h2>

        <div className="filters">
          <label htmlFor="project-filter" className="filter-label">
            Filter by Project Name:
          </label>
          <select
            id="project-filter"
            value={filters.projectName}
            onChange={(e) =>
              setFilters({ ...filters, projectName: e.target.value })
            }
            className="filter-dropdown"
          >
            <option value="">All Projects</option>
            {uniqueProjects.map((project, index) => (
              <option key={index} value={project}>
                {project}
              </option>
            ))}
          </select>

          <label htmlFor="item-filter" className="filter-label">
            Filter by Item Name:
          </label>
          <select
            id="item-filter"
            value={filters.itemName}
            onChange={(e) =>
              setFilters({ ...filters, itemName: e.target.value })
            }
            className="filter-dropdown"
          >
            <option value="">All Items</option>
            {uniqueItems.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="action-icons">
          <FaFileExcel
            className="excel-icon"
            onClick={handleExportToExcel}
            title="Export to Excel"
          />
          <FaPrint
            className="print-icon"
            onClick={handlePrint}
            title="Print Table"
          />
        </div>
      </div>

      <div id="print-section">
        <table
          className="summary-table"
          aria-label="Projects & Items Report Summary"
        >
          <thead>
            <tr>
              <th>Project #</th>
              <th>Project Name</th>
              <th>Total Deposit</th>
              <th>Total Withdrawals</th>
              <th>Current Balance</th>
              <th>Items Name</th>
              <th>Total Debit</th>
              <th>Total Credit</th>
              <th>Total Item Balance</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.project_number}</td>
                  <td>{item.project_name}</td>
                  <td>${Number(item.total_deposit || 0).toFixed(2)}</td>
                  <td>${Number(item.total_withdrawals || 0).toFixed(2)}</td>
                  <td>${Number(item.current_balance || 0).toFixed(2)}</td>
                  <td>{item.tab_name || "N/A"}</td>
                  <td>${Number(item.total_debit || 0).toFixed(2)}</td>
                  <td>${Number(item.total_credit || 0).toFixed(2)}</td>
                  <td>${Number(item.tab_balance || 0).toFixed(2)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectSummary;
