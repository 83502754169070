import React from 'react';
import './Header.css';

const Header = ({ project }) => {
    const balance = project?.balance ? parseFloat(project.balance).toFixed(2) : '0.00';

    return (
        <div className="header">
            <h1 className="header-title">{project.name}</h1>
            <div className="header-details">
            <span>Project Fund Balance: ${balance}</span>
                <span>Started: {new Date(project.start_date).toLocaleDateString()}</span>
                <span>
                    Completed: 
                    {project.completed_date 
                        ? new Date(project.completed_date).toLocaleDateString() 
                        : 'Not Completed'}
                </span>
               
            </div>
        </div>
    );
};

export default Header;
